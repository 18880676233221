body {
    font-family: 'Fira Sans', sans-serif;
}

.partial-translation-overlay {
    position: absolute;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 1rem;
    width: 150px;
    left: -0.5rem;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    z-index: 999;
}

.partial-translation {
    cursor: pointer;
    padding: 0 0.5em;
    border-radius: 5px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    position: relative;
    text-shadow: 0 1px 0px #fff;
    z-index: 2;
    box-sizing: border-box;
}
.partial-translation:after {
    z-index: 1;
    content: "";
    display: block;
    position: absolute;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-shadow: inset 0 -2px 1px rgba(0, 0, 0, 0.9), inset 0 2px 1px white;
}

.partial-translation.new {
    border: 3px solid #0000aa;
}
.partial-translation.new:after {
    background: #0000ff;
    box-shadow: 4px 4px 8px #00d, -4px -4px 8px #00d;
}
.partial-translation.new:before {
    content: "NEW";
    font-weight: 600;
    font-size: 0.8rem;
    color: rgb(235, 248, 255);
    position: absolute;
    right: -12px;
    top: -16px;
    text-shadow: 0 1px 2px #000, 0 1px 2px #000;
    z-index: 99;
}

.partial-translation.emerald {
    border: 3px solid rgb(18, 133, 37);
}
.partial-translation.emerald:after {
    background: linear-gradient(120deg,
        rgb(217, 241, 218),
        rgb(15, 206, 15) 50%,
        rgb(105, 192, 65) 57%,
        rgb(46, 104, 19));
}

.partial-translation.gold {
    border: 3px solid rgb(255, 217, 0);
}
.partial-translation.gold:after {
    background: linear-gradient(120deg,
        rgb(245, 243, 123),
        rgb(236, 204, 22) 50%,
        rgb(236, 178, 20) 57%,
        rgb(214, 162, 49));
}

.partial-translation.silver {
    border: 3px solid rgba(226, 226, 226, 0.959);
}
.partial-translation.silver:after {
    background: linear-gradient(120deg,
        rgb(214, 214, 214),
        rgb(170, 170, 169) 50%,
        rgb(187, 187, 187) 57%,
        rgb(85, 85, 85));
}

.partial-translation.silver {
    border: 3px solid rgba(226, 226, 226, 0.959);
}
.partial-translation.silver:after {
    background: linear-gradient(120deg,
        rgb(214, 214, 214),
        rgb(170, 170, 169) 50%,
        rgb(187, 187, 187) 57%,
        rgb(85, 85, 85));
}

.partial-translation.bronze {
    border: 3px solid rgba(167, 114, 18, 0.959);
}
.partial-translation.bronze:after {
    background: linear-gradient(120deg,
        rgb(165, 147, 66),
        rgb(116, 83, 24) 50%,
        rgb(187, 83, 35) 57%,
        rgb(83, 32, 17));
}

.partial-translation.completed {
    box-shadow: none;
    border: 2px solid rgba(0, 0, 0, 0.15);
}
.partial-translation.completed:after {
    display: none;
}

.icon-button .MuiFab-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
}
/*.partial-translation:before {
    z-index: -1;
    content: "";
    display: block;
    position: absolute;
    background: radial-gradient(circle at 95% 5%, #fff, transparent 30%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}*/

.confetti-wrapper, .confetti-wrapper > div {
    display: inline-block;
}

.progress-bar {
    position: relative;
    height: 20px;
    border-radius: 50px;
    border: 1px solid #333;
}
  
.filler {
    background: #1DA598;
    height: 100%;
    border-radius: inherit;
    transition: width .2s ease-in;
}

#vids {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#vids a:first-of-type {
    margin-bottom: 5px;
}
  
.coverImage {
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-right: -16px;
}

.MuiListSubheader-sticky {
    font-size: 15px;
    margin: 5px;
}

.subheader-0 {
    background-color: rgba(167, 114, 18, 0.959) !important;
}

.subheader-1 {
    background-color: rgba(226, 226, 226, 0.959) !important;
}

.subheader-2 {
    background-color: rgb(255, 217, 0) !important;
}

.subheader-3 {
    background-color: rgb(18, 133, 37) !important;
}

.subheader-4 {
    background-color:rgb(217, 241, 218) !important;
}

#addText {
    height: 100px!important;
}

@media screen and (min-width: 500px) {
.phone-wrapper {
    max-width: 412px;
    max-height: 732px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15),
        0 30px 80px rgba(0, 0, 0, 0.15);
    overflow: scroll;
    scrollbar-width: none;
    border-radius: 5px;
    border: 1px solid #888;
}
.phone-wrapper > *:not(header) {
    width: 412px;
    height: 732px;
}

.phone-wrapper-outer {
    display: flex;
    align-items: center;
    height: 100vh;
    justify-content: center;
}
}
